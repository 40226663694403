html,
body {
  font-family: 'Chivo', sans-serif;
  /* background: #082568; */
  background: #02194e;
  color: #ffffff;
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  left: 4vw;
  top: 4vw;
  z-index: 1;
  width: 9vw;
}

/* #root {
  display: flex;
  align-items: center;
  justify-content: center;
} */

@media only screen and (max-width: 768px) {
  .logo {
    left: 6vw;
    top: 6vw;
    width: 40vw;
  }
}

.description {
  position: absolute;
  left: 4vw;
  top: 20vh;
  z-index: 2;
  width: 15vw;
  /* text-shadow: 0px 0px 12px rgba(0, 43, 130, 0.9), 0px 0px 16px rgba(0, 43, 130, 0.9); */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  display: block;
}

.description.hide {
  opacity: 0;
}

.description.hide.removed {
  display: none;
}

a {
  color: #ffffff;
  font-weight: normal;
}

a:hover {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .description {
    left: 0;
    bottom: 6vw;
    width: 100vw;
    top: auto;
  }
}

.title {
  font-weight: normal;
  z-index: 1;
  font-size: 1.9vw;
  padding-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 4.2vw;
    text-align: center;
  }
}

.description p {
  font-weight: 300;
  font-size: 0.9vw;
  line-height: 1.5vw;
}

@media only screen and (max-width: 768px) {
  .description p {
    display: none;
  }
}

/* Popup */
.modal {
  color: #0033ad;
  background: #fff;
  position: fixed;
  width: 300px;
  min-height: 320px;
  z-index: 2;
  padding: 100px 20px 20px 20px;
  filter: drop-shadow(10px 10px 0px #0033ad);
  display: none;
}

@media only screen and (max-width: 768px) {
  .modal {
    left: 10px!important;
    top: 10px!important;
    position: relative;
  }
}

.modal.open {
  display: block;
}

.modal h1 {
  padding: 0 0 8px;
  margin: 0;
  font-weight: bold;
  font-size: 29px;
  width: 270px;
}

.modal h2 {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}

.modal h3 {
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}

.modal p {
  padding: 0;
  margin: 30px 0 0;
  font-weight: normal;
  font-size: 16px;
}

.modal ul {
  list-style: none;
  padding: 0;
}

.close {
  position: absolute;
  right: 5px;
  top: 10px;
  border: none;
  background: #fff;
  border-radius: 50px;
  width: 37px;
  height: 37px;
  padding: 0;
  margin: 0;
  color: #0033ad;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.close:hover {
  color: #000000;
}

.modal p.listTitle {
  font-weight: bold;
  margin: 0;
  padding: 14px 0 0;
}

.modal ul.emailList {
  margin: 0;
  padding: 0 0 10px;
}

.close:hover {
  cursor: pointer;
}

/* Footer */
footer {
  display: flex;
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100vw;
  justify-content: flex-end;
  font-size: 11px;
  font-weight: 100;
  color: #bcc8e2;
}

@media only screen and (max-width: 768px) {
  footer {
    justify-content: center;
  }
}

footer a {
  color: #bcc8e2;
  margin: 0 20px 0 0;
  text-decoration: none;
  transition: all 0.3s;
}

@media only screen and (max-width: 768px) {
  footer a {
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer-inner {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-links {
    padding: 0px 0 7px;
  }
}

footer a,
footer .copyright {
  /* text-shadow: 0px 0px 3px rgba(0, 43, 130, 1), 0px 0px 3px rgba(0, 43, 130, 1); */
}

@media only screen and (max-width: 768px) {
  .copyright {
    text-align: center;
    /* text-shadow: 0px 0px 3px rgba(0, 43, 130, 1), 0px 0px 3px rgba(0, 43, 130, 1), 0px 0px 3px rgba(0, 43, 130, 1), 0px 0px 3px rgba(0, 43, 130, 1); */
  }
}

footer a:hover {
  color: #ffffff;
}

.footer-inner {
  padding: 0 22px 10px 0;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .footer-inner {
    padding: 0 5px;
    display: block;
  }
}

/* Loader */
.loader-wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loader-container {
  scale: 0.4;
  width: 400px;
  height: 400px;
  position: relative;
}

.loader-container div {
  position: absolute;
}

.first-ring {
  width: 50px;
  height: 50px;
}

.ring-1 {
  left: 140px;
  top: 103px;
}

.ring-2 {
  left: 202px;
  top: 103px;
}

.ring-3 {
  left: 233px;
  top: 156px;
}

.ring-4 {
  left: 202px;
  top: 210px;
}

.ring-5 {
  left: 140px;
  top: 210px;
}

.ring-6 {
  left: 109px;
  top: 156px;
}

.second-ring {
  width: 30px;
  height: 30px;
}

.ring-7 {
  left: 181px;
  top: 60px;
}

.ring-8 {
  left: 273px;
  top: 113px;
}

.ring-9 {
  left: 273px;
  top: 219px;
}

.ring-10 {
  left: 180px;
  top: 273px;
}

.ring-11 {
  left: 89px;
  top: 219px;
}

.ring-12 {
  left: 89px;
  top: 113px;
}

.third-ring {
  width: 28px;
  height: 28px;
}

.ring-13 {
  left: 251px;
  top: 47px;
}

.ring-14 {
  left: 320px;
  top: 167px;
}

.ring-15 {
  left: 252px;
  top: 287px;
}

.ring-16 {
  left: 113px;
  top: 287px;
}

.ring-17 {
  left: 44px;
  top: 168px;
}

.ring-18 {
  left: 113px;
  top: 47px;
}

.fourth-ring {
  width: 21px;
  height: 21px;
}

.ring-19 {
  left: 185px;
  top: 0px;
}

.ring-20 {
  left: 333px;
  top: 85px;
}

.ring-21 {
  left: 333px;
  top: 255px;
}

.ring-22 {
  left: 185px;
  top: 340px;
}

.ring-23 {
  left: 38px;
  top: 256px;
}

.ring-24 {
  left: 38px;
  top: 86px;
}

.fifth-ring {
  width: 19px;
  height: 19px;
}

.ring-25 {
  left: 279px;
  top: 8px;
}

.ring-26 {
  left: 374px;
  top: 171px;
}

.ring-27 {
  left: 280px;
  top: 332px;
}

.ring-28 {
  left: 93px;
  top: 333px;
}

.ring-29 {
  left: 0px;
  top: 171px;
}

.ring-30 {
  left: 92px;
  top: 9px;
}

/* Date Selector */
.month-selector-bg {
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.8) 0%, rgba(2, 0, 36, 0.8) 50%, rgba(2, 41, 134, 0) 100%);
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 178px;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.month-selector-wrapper {
  position: fixed;
  left: 0;
  bottom: 50px;
  z-index: 2;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  /* background: rgba(255, 255, 255, 0.05); */
}

@media only screen and (max-width: 768px) {
  .month-selector-wrapper {
    display: none;
  }
}

.month-selector-inner {
  width: 100%;
  max-width: 1300px;
  display: flex;
  border-top: 1px solid #022986;
  padding: 15px 0 0;
  margin: 0 20px;
}

.month-markers {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.month-markers p {
  position: absolute;
  left: 0;
  top: -60px;
  font-weight: 100;
  font-size: 14px
}

.month-markers p::before {
  content: ' ';
  display: block;
  background: #022986;
  left: 0;
  top: 22px;
  width: 1px;
  height: 15px;
  opacity: 1.0;
  position: absolute;
}

.month-container {
  width: 100%;
}

.month-container span {
  width: 20px;
  height: 25px;
  transition: all 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.month-container span span {
  width: 5px;
  height: 25px;
  display: block;
  background: #ff0000;
  opacity: 0.5;
  transition: all 0.1s;
}

.month-container span:hover,
.month-container span:hover span,
.month-container span.selected span {
  cursor: pointer;
  opacity: 1.0;
}

.react-tooltip {
  z-index: 2;
}

.tooltip-content {
  text-align: center;
}

.description-container {
  z-index: 3;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-container div {
  opacity: 0;
  width: 100vw;
  text-align: center;
  bottom: 100px;
  position: absolute;
  font-size: 26px;
}

@media only screen and (max-width: 768px) {
  .description-container div {
    font-size: 18px;
    line-height: 41px;
  }
}

.description-container div span {
  /* border-radius: 10px; */
  text-shadow:
    0px 0px 3px rgba(2, 0, 36, 1),
    0px 0px 3px rgba(2, 0, 36, 1),
    0px 0px 3px rgba(2, 0, 36, 1),
    0px 0px 3px rgba(2, 0, 36, 1);
  background-color: rgba(2, 0, 36, 0.4);
  padding: 8px 12px;
}

.description-container div.text-1 {
  bottom: auto;
  top: 100px;
}

.description-container div.text-2 {
  bottom: auto;
  top: 100px;
}

.description-container div.text-2 {
  bottom: auto;
  top: 100px;
}

.description-container div.cta span {
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.description-container div.cta span:hover {
  cursor: pointer;
}

.description-container div.cta {
  font-size: 20px;
  font-weight: 300;
  position: relative;
  bottom: auto;
  display: none;
}

.description-container div.cta span a {
  text-decoration: none;
}